<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import InfoTable from "@/components/listings/Info-table";
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";
import { loopDetails } from "@/util/mapfunction.js";
import { badgeColor } from "@/util/mapfunction.js";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";
import InputModal from "@/components/modals/input-modal";
import LoadingSpinner from '@/components/Loading';

export default {
    components: {
        Layout,
        PageHeader,
        InfoTable,
        ConfirmationModal,
        MessageModal,
        InputModal,
        LoadingSpinner
    },
    data() {
        const id = this.$route.params.id;

        return {
            title: `CTC`,
            items: [
                {
                    text: "CTC"
                },
                {
                    text: 'View',
                    active: true,
                },
            ],
            id: id,
            ctc: {
                order_display_id: {
                    label: "CTC ID",
                    value: "",
                },
                ctc_quantity: {
                    label: "CTC Quantity",
                    value: "",
                },
                shipping_name: {
                    label: "Recipient Name",
                    value: "",
                },
                shipping_phone: {
                    label: "Recipient Phone",
                    value: "",
                },
                shipping_address_full: {
                    label: "Recipient Address",
                    value: "",
                },
                courier_company: {
                    label: "Courier Company",
                    value: "",
                },
                shipping_tracking_no: {
                    label: "Shipping Tracking No",
                    value: "",
                },
                request_at: {
                    label: "Date",
                    value: "",
                },
            },
            ctc_api: {},
            tenancy_api: {},
            order_api: {},
            orderTotals: [],
            status: '',
            statusOptions: [
                {"name":"pending",             "label":"Pending",                   "className": "success"},
                {"name":"payment_processing",       "label":"Payment Processing",   "className": "badge-warning"},
                {"name":"delivered",          "label":"Delivered",                  "className": "success"},
            ],
            update_status_error: '',
            update_status: "",
            isLoading: false
        }
    },
    async created() {
        this.isLoading = true;
        const result = await axios.get(`${process.env.VUE_APP_APIURL}/ctc/${this.id}`, {
            headers: authHeader(),
        }).catch( function (error) {
            alert("Load Ctc error:" + error.message);
            this.isLoading = false;
        });

        if (result.data.code ==='success') {
            const { data } = result.data;
            loopDetails(this.ctc, data);
            this.order_api = data;
            this.ctc_api = data.ctc;
            this.tenancy_api = data.tenancy_detail;
            this.orderTotals = data.order_totals;
            this.status = data.ctc.status;
            this.update_status = data.ctc.status;
            this.ctc_quantity = data.ctc.ctc_quantity;
            this.ctc.ctc_quantity.value = data.ctc.ctc_quantity;
            this.ctc.request_at.value = data.ctc.created_at;
            this.ctc.order_display_id.value = data.ctc.order_display_id;
            console.log('ctcssss', this.ctc)
            this.isLoading = false;
        }
    },
    methods: {
        badgeMap(val) {
            if (val != '') {
                return badgeColor("ctc", val);
            }
            return;
        },
        mapStatusName(val) {
            if (val != '' && val !== null) {
                let index = this.statusOptions.findIndex(obj => obj.name === val);
                return  this.statusOptions[index].label;
            }
        },
        chgStatus() {
            const updateCallback = async () => {
                const result =await axios.put(`${process.env.VUE_APP_APIURL}/ctc-status/${this.ctc_api.id}`, { "status" : this.update_status } ,{
                    headers: authHeader(),
                }).catch( function (error) {
                    alert("Update fail:" + error.message);
                });

                this.$refs.confirmationModal.closeModal();
                if (result.data.code === 'success') {
                    this.$refs.messageModal.showModal("Update success");
                    this.status = result.data.data.status;
                } else if (result.data.code === 'invalid_field') {
                    this.$refs.messageModal.showModal(result.data.errors.status);
                } else if (result.data.code === "permission_denied") {
                    this.$refs.confirmationModal.closeModal();
                    this.$refs.messageModal.showModal("Permission Denied");
                } 
            };
            let index = this.statusOptions.findIndex(obj => obj.name === this.update_status);
            let label = index != -1 ? this.statusOptions[index].label : '';
            if (label != -1) {
                this.$refs.confirmationModal.showModal(
                    `Update status to "${label}"`,
                    updateCallback
                );
            }
        }
    }
}
</script>

<template>
    <Layout>
        <LoadingSpinner :isLoading="isLoading" />
        <PageHeader :title="title" :items="items" />
        <ConfirmationModal ref="confirmationModal" />
        <MessageModal ref="messageModal"/>
        <InputModal ref="statusModel" title="Update Status" :preventClose="true">
        <template #inputField >
          <div class="form-group">
            <label for="order_item_status">Select a status to update:</label>
            <select class="form-control mb-2" id="order_item_status" v-model="update_status">
              <option value="" disabled>Select status</option>
              <option value="payment_processing">Payment Processing</option>
              <option value="pending">Pending</option>
              <option value="delivered">Delivered</option>
            </select>
            <div class="alert alert-danger mt-3" role="alert" v-if="update_status_error != ''">
              {{ update_status_error }}
            </div>
            <button class="btn btn-orange" @click="chgStatus">
              Update
            </button>
          </div>
        </template>
      </InputModal>

        <div class="card" style="border-radius: 15px;">
            <div class="card-body">
                <div class="row mt-2 ">

                    <div class="col-12 d-flex">
                        <!-- <router-link
                            class="btn btn-success mb-2"
                            :to="{ name: 'Ctc Edit', params: { id: this.order_api.id } }"
                            ><i class="ri-pencil-line font-size-12 mr-2"></i>
                            Edit Details
                        </router-link> -->
                    </div>

                    <div class="col-12 d-flex" v-if="tenancy_api.hasOwnProperty('order_id')">
                        <h4 class="subTitle-font mb-0 mr-2">Tenancy: </h4> 
                        <router-link :to="{ name: 'Tenancy View New', params: { id: tenancy_api.order_id } }">
                            {{ tenancy_api.order_display_id }} 
                        </router-link>
                    </div>

                    <div class="col-12 d-flex align-items-center mb-2 justify-content-start flex-wrap">
                        <h4 class="subTitle-font mb-0 mr-2">Current Status: </h4> 
                        <div :class="'badge font-size-12 ' + badgeMap(status)">{{ mapStatusName(status) }}</div>
                        <div class="button_group ml-auto" v-if="status != 'delivered'">
                            <router-link
                            class="btn btn-sm btn-success"
                            :to="{ name: 'Ctc Edit', params: { id: this.order_api.id } }"
                            >
                                Edit Details
                            </router-link>
                            <button class="btn btn-sm btn-orange ml-2" @click="$refs.statusModel.showModal()">Change Status</button>
                            <!-- <button style="border: 0 !important;" v-for="statusOption in statusOptions" :value="statusOption.name"
                                :key="statusOption.name" :class="'btn-' + statusOption.className + ' btn-sm ml-1 mr-1'" @click="chgStatus(statusOption.name, $event)">
                                {{ statusOption.label  }}
                            </button>
                            <router-link :to="{ name: 'Ctc Add Shipping', params: { id: ctc_api.id}, query: { tenancy: order_api.id } }" 
                                class="ml-1 mr-1 btn-sm btn-success" v-if="order_api.hasOwnProperty('id')">
                                Add Tracking Number
                            </router-link>  -->
                        </div>
                    </div>

                    <div class="col-12 col-sm-6 mb-2">
                        <InfoTable tableHeaderText="CTC Details" :tableData="ctc" />
                    </div>

                    <!-- <table class="table">
                        <tr>
                            <th>Item</th>
                            <th>Quantity</th>
                            <th>Price</th>
                        </tr>
                        <tr v-if="order_api.same_order">
                            <th>Service Fee</th>
                            <th>-</th>
                            <th>{{ tenancy_api.unit_price }}</th>
                        </tr>
                        <tr v-if="order_api.same_order">
                            <th>Stamping Fee</th>
                            <th>-</th>
                            <th>{{ tenancy_api.unit_stamping_fee }}</th>
                        </tr>
                        <tr>
                            <th>Ctc Fee</th>
                            <th>{{ ctc_api.ctc_quantity }}</th>
                            <th>{{ ctc_api.price }}</th>
                        </tr>
                    </table>  -->
                </div>

                <!-- <div class="row justify-content-end">
                    <div class="col-6">
                        <table class="ct_bordered_table table"> 
                            <tr v-for="totals in orderTotals" :key="totals.id">
                                <th class="table_title w-50 text-right">{{ totals.name }}</th>
                                <td class="w-50 text-right">RM {{ totals.value }}</td>
                            </tr>
                        </table>
                    </div>
                </div> -->
            </div>
        </div>
    </Layout>
</template>